import React, { useEffect, useRef, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import db from "../firebase";
import { UserContext } from "./User";
import Cookies from "js-cookie";
import useAnalytics from "../hooks/useAnalytics";
import useScore from "../hooks/useScore";
import useProgress from "../hooks/useProgress";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import ConfettiComp from "./ConfettiComp";
import PopUpMessage from "./PopUpMessage";
import { gsap } from "gsap";
import { DrawSVGPlugin } from '../plugins/DrawSVGPlugin.min.js'
import { set } from "firebase/database";
import { DataContext } from "./DataContext";

gsap.registerPlugin(DrawSVGPlugin);

export default function MindARViewer2({ points }) {

	const {DATA, setData} = useContext(DataContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/,'')-1);

	const sceneRef = useRef(null);
	const { user, setUser } = useContext(UserContext);
	const [resize, setResize] = useState(false);
	const [loop, setLoop] = useState(0);
	const [generateText, setGenerateText] = useState("Analyzing Data...");
	const [avatarMinted, setAvatarMinted] = useState(false);
	const [mintReady, setMintReady] = useState(false);
	const [popUp, setPopUp] = useState(false);
	const [fire, setFire] = useState(false);
	const [popUpMessage, setPopUpMessage] = useState(null);
	const [bg, setBg] = useState("/img/bg/bg" + 0 + ".png");
	const [circle, setCircle] = useState("/img/circle/circle" + 0 + ".png");
	const [face, setFace] = useState("/img/face/face" + 0 + ".png");
	const [hat, setHat] = useState("/img/hat/hat" + 0 + ".png");
	const [body, setBody] = useState("/img/body/body" + 0 + ".png");
	const navigate = useNavigate();
	const id = Cookies.get().id;
	const [updateScore] = useScore(points, id);
	const [updateProgress] = useProgress(user.progress, id);
	const [addAnalytics] = useAnalytics("Generate avatar", id, {}, user.project_id);
	const [package_points, setPackage_points] = useState(0);
	const isMounted = useRef(true);
	const intervalRef = useRef(null);
	const timeoutRef = useRef(null);
	const buttonRef = useRef(null);

// 	useEffect(() => {
// 		if (id !== undefined) {
// 			const unsub = onSnapshot(doc(db, "users", id), (doc) => {
// 				console.log("get user from cookie - mindar");
// 				setUser(doc.data());
// 			});
// 			return () => unsub();
// 		}
// 	}, [id]);


	// Getting total points for the current project
	useEffect(() => {
		if (user.progress && user.project_id && user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
			console.log('PACKAGE POINTS: ' + package_points);
		}
	}, [user, user.progress, user.project_id]);

	function onModalChange(newState) {   
		setPopUp(newState);
	}

	const handleButtonClick = async () => {
		if (!isMounted.current) return;

		try {
			document.querySelector(".generating-text")?.classList.add("expanded");
			document.querySelector(".button")?.classList.remove("ready");
			setPopUpMessage("You just unlocked Module Two!");
			setPopUp(true);
			setFire(true);
			setGenerateText("");
			await addAnalytics();
			await update();
			document.body.style.overflow = "visible";
		} catch (error) {
			console.error("Error in handleButtonClick:", error);
		}
	};

	const update = async () => {
		try {
			if (!isMounted.current) return;

			// First update score and wait for it to complete
			await updateScore();
			await addAnalytics();

			// Then update the user document
			const user_ref = doc(db, "users", Cookies.get().id);
			const updatedUser = { ...user };

			// Handle reward
			if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']) {
				let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];
				if (reward.name !== '') {
					updatedUser.rewards.push({
						name: reward.name,
						price: reward.price,
						image: reward.image,
						redeemed: false,
						instruction: reward?.instruction,
					});
				}
			}

			// Update progress
			updatedUser.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
			updatedUser.progress[user.project_id]['packets'][packet_index].package_points = 
				parseInt(updatedUser.progress[user.project_id]['packets'][packet_index].package_points) + parseInt(points);

			// Update Firestore
			await updateDoc(user_ref, {
				avatarRevealed: true,
				rewards: updatedUser.rewards,
				progress: updatedUser.progress
			});

			return true;
		} catch (error) {
			console.error("Error updating user:", error);
			throw error;
		}
	};

	// Animation and interval setup effect
	useEffect(() => {
		let time = 500;
		
		intervalRef.current = setInterval(() => {
			if (!isMounted.current) return;
			
			time += 500;
			if (time >= 8500) {
				setGenerateText("Data processed. Click for avatar.");
			}
		}, 500);

		timeoutRef.current = setTimeout(() => {
			if (!isMounted.current) return;

			let tl = gsap.timeline({ autoplay: true });
			tl.set("#loading line", { drawSVG: '100% 40%' })
			  .staggerTo("#loading line", 2, { 
				  drawSVG: '-100% 100%', 
				  stroke: "var(--buttons_background)" 
			  }, 0.1)
			  .to("#face", { 
				  transformOrigin: "center center", 
				  rotateY: 25, 
				  rotateX: -25, 
				  scale: 0.6, 
				  y: "-10%" 
			  }, 7);
		}, 1000);

		// Add button click handler
		const buttonElement = document.querySelector(".button");
		if (buttonElement) {
			buttonElement.addEventListener("click", handleButtonClick);
			buttonRef.current = buttonElement;
		}

		return () => {
			if (buttonRef.current) {
				buttonRef.current.removeEventListener("click", handleButtonClick);
			}
			if (intervalRef.current) clearInterval(intervalRef.current);
			if (timeoutRef.current) clearTimeout(timeoutRef.current);
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event("resize"));
		}, 2500);
	}, [resize]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setBg("/img/bg/bg" + loop + ".png");
	// 	}, 500);
	// }, [loop]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setCircle("/img/circle/circle" + loop + ".png");
	// 	}, 500);
	// }, [loop]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setFace("/img/face/face" + loop + ".png");
	// 	}, 500);
	// }, [loop]);

	// Cleanup effect
	useEffect(() => {
		return () => {
			isMounted.current = false;
			if (intervalRef.current) clearInterval(intervalRef.current);
			if (timeoutRef.current) clearTimeout(timeoutRef.current);
			buttonRef.current?.removeEventListener("click", handleButtonClick);
		};
	}, []);

	return (
		<div className="ar-wrapper">
			{/* <div className="bg"></div> */}
			{/* <img className="bg" src={bg}></img> */}
			{/* <img className="circle" src={circle}></img> */}
			<div className="ar-scene-wrap">
				{/* <a-scene
					ref={sceneRef}
					mindar-face="autoStart: false; uiLoading: no; uiError: no;"
					vr-mode-ui={"enabled: false"}
					loading-screen={"dotsColor: red; backgroundColor: black"}
					device-orientation-permission-ui={"enabled: false"}
					color-space="grayscale"
					renderer="colorManagement: true, physicallyCorrectLights"
				>
					<a-camera
						active={false}
						position={"0 0 0"}
						look-controls="reverseMouseDrag:true; touchEnabled: false"
					/> */}
					{/* <a-entity mindar-face-target={"anchorIndex: 3"}>
						<a-image position="0 0 0.3" scale="1.3 1.4 1.45" src={face}></a-image>
					</a-entity> */}
					{/* <a-entity mindar-face-target={"anchorIndex: 10"}>
						<a-image
							position="0 -0.1 0.5"
							scale="1.38 0.57 1.45"
							src={hat}
						></a-image>
					</a-entity> */}
					{/* <a-entity mindar-face-target={"anchorIndex: 3"}>
						<a-image
							position="0 -0.89 0.32"
							scale="0.8 0.8 1.85"
							src={body}
						></a-image>
					</a-entity> */}
				{/* </a-scene> */}
			</div>
			<div className="face-id-overlay">
				<div className="finalized"></div>
				<svg id="face-id-set-up" xmlns="http://www.w3.org/2000/svg" width="300" height="300" viewBox="0 0 151.42 151.42">
					<g id="loading" stroke="#b6b6b6">
						<line x1="65" y1="149.72" x2="66.08" y2="138.78" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="57.8" y1="148.32" x2="59.94" y2="137.53" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="50.77" y1="146.21" x2="53.96" y2="135.69" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="43.98" y1="143.43" x2="48.19" y2="133.27" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="37.49" y1="139.99" x2="42.68" y2="130.29" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="31.38" y1="135.94" x2="37.49" y2="126.79" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="25.69" y1="131.3" x2="32.66" y2="122.8" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="20.48" y1="126.13" x2="28.26" y2="118.35" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="15.8" y1="120.47" x2="24.3" y2="113.5" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="11.7" y1="114.39" x2="20.85" y2="108.28" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="8.22" y1="107.93" x2="17.92" y2="102.74" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="5.39" y1="101.16" x2="15.55" y2="96.95" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="3.23" y1="94.14" x2="13.76" y2="90.95" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="1.77" y1="86.95" x2="12.56" y2="84.8" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="1.03" y1="79.65" x2="11.97" y2="78.57" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="1" y1="72.31" x2="12" y2="72.31" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="1.69" y1="65" x2="12.64" y2="66.08" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="3.1" y1="57.8" x2="13.89" y2="59.94" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="5.2" y1="50.77" x2="15.73" y2="53.96" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="7.99" y1="43.98" x2="18.15" y2="48.19" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="11.42" y1="37.49" x2="21.13" y2="42.68" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="15.48" y1="31.38" x2="24.63" y2="37.49" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="20.12" y1="25.69" x2="28.62" y2="32.66" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="25.29" y1="20.48" x2="33.06" y2="28.26" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="30.94" y1="15.8" x2="37.92" y2="24.3" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="37.03" y1="11.7" x2="43.14" y2="20.85" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="43.49" y1="8.22" x2="48.68" y2="17.92" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="50.26" y1="5.39" x2="54.47" y2="15.55" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="57.28" y1="3.23" x2="60.47" y2="13.76" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="64.47" y1="1.77" x2="66.61" y2="12.56" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="71.77" y1="1.03" x2="72.85" y2="11.97" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="79.11" y1="1" x2="79.11" y2="12" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="86.41" y1="1.69" x2="85.34" y2="12.64" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="93.62" y1="3.1" x2="91.47" y2="13.89" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="100.65" y1="5.2" x2="97.46" y2="15.73" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="107.44" y1="7.99" x2="103.23" y2="18.15" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="113.92" y1="11.42" x2="108.74" y2="21.13" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="120.04" y1="15.48" x2="113.93" y2="24.63" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="125.73" y1="20.12" x2="118.75" y2="28.62" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="130.94" y1="25.29" x2="123.16" y2="33.06" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="135.62" y1="30.94" x2="127.11" y2="37.92" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="139.72" y1="37.03" x2="130.57" y2="43.14" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="143.2" y1="43.49" x2="133.5" y2="48.68" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="146.03" y1="50.26" x2="135.87" y2="54.47" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="148.19" y1="57.28" x2="137.66" y2="60.47" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="149.64" y1="64.47" x2="138.86" y2="66.61" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="150.39" y1="71.77" x2="139.44" y2="72.85" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="150.42" y1="79.11" x2="139.42" y2="79.11" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="149.72" y1="86.41" x2="138.78" y2="85.34" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="148.32" y1="93.62" x2="137.53" y2="91.47" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="146.21" y1="100.65" x2="135.69" y2="97.46" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="143.43" y1="107.44" x2="133.27" y2="103.23" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="139.99" y1="113.92" x2="130.29" y2="108.74" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="135.94" y1="120.04" x2="126.79" y2="113.93" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="131.3" y1="125.73" x2="122.8" y2="118.75" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="126.13" y1="130.94" x2="118.35" y2="123.16" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="120.47" y1="135.62" x2="113.5" y2="127.11" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="114.39" y1="139.72" x2="108.28" y2="130.57" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="107.93" y1="143.2" x2="102.74" y2="133.5" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="101.16" y1="146.03" x2="96.95" y2="135.87" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="94.14" y1="148.19" x2="90.95" y2="137.66" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="86.95" y1="149.64" x2="84.8" y2="138.86" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="79.65" y1="150.39" x2="78.57" y2="139.44" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
						<line x1="72.31" y1="150.42" x2="72.31" y2="139.42" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="2" />
					</g>
					<g id="face" stroke="#b6b6b6">
						<circle id="face-contour" cx="75.31" cy="76.42" r="27.5" fill="none" stroke-miterlimit="10" strokeWidth="3" />
						<g id="eyes">
							<line id="right" x1="87.31" y1="69.42" x2="87.31" y2="73.42" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="4" />
							<line id="left" x1="63.31" y1="69.42" x2="63.31" y2="73.42" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="4" />
						</g>
						<g id="nose">
							<line x1="76.68" y1="69.17" x2="76.68" y2="81.67" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="3" />
							<line x1="72.81" y1="81.67" x2="76.63" y2="81.67" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="3" />
						</g>
						<path id="mouth" d="M84.31,88.42a12.27,12.27,0,0,1-17.36,0" fill="none" strokeLinecap="round" stroke-miterlimit="10" strokeWidth="3.18" />
					</g>
				</svg>
				<a className="button" onClick={handleButtonClick}>
					Reveal
				</a>
			</div>
			<div className="generating-text">{generateText}</div>
			<ConfettiComp fire={fire} />
			<PopUpMessage onModalChange={onModalChange} trigger={popUp}>
				{
					<div>
						<h2>Congratulations! You just minted your unique avatar!</h2>
						<img src={user.avatarUrl}></img>
					</div>
				}
			</PopUpMessage>
		</div>
	);
}
