import React, { useState, useCallback, useMemo, useEffect } from "react";
import Cookies from "js-cookie";
import { doc, onSnapshot } from "firebase/firestore";
import db from "../firebase";

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let unsubscribe;
        const userId = Cookies.get('id');

        if (userId) {
            unsubscribe = onSnapshot(
                doc(db, "users", userId),
                (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setUser({
                            id: docSnapshot.id,
                            ...docSnapshot.data(),
                            setprogress: false
                        });
                    } else {
                        // User document doesn't exist, clear cookies
                        console.log('User document not found, clearing session');
                        Cookies.remove('id');
                        Cookies.remove('phoneNumber');
                        setUser(null);
                    }
                    setIsLoading(false);
                },
                (error) => {
                    console.error('Error fetching user:', error);
                    setIsLoading(false);
                }
            );
        } else {
            setUser(null);
            setIsLoading(false);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, []);

    const updateUser = useCallback((updates) => {
        setUser(prev => ({
            ...prev,
            ...updates
        }));
    }, []);

    const value = useMemo(() => ({
        user,
        setUser: updateUser,
        isLoading
    }), [user, updateUser, isLoading]);

    if (isLoading) {
        return null;
    }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};
