import { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "./User";
import { doc, updateDoc, addDoc, collection } from "firebase/firestore";
import db from "../firebase";
import { useForm } from "react-hook-form";
import Footer from "./Footer";
import ArrowLeft from "./ArrowLeft";
import TaskCard from "./TaskCard";
import Cookies from "js-cookie";
import useScore from "../hooks/useScore";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useAnalytics from "../hooks/useAnalytics";
import ConfettiComp from "./ConfettiComp";
import PopUpMessage from "./PopUpMessage";
import PopUpBio from "./PopUpBio";
import { DataContext } from "./DataContext";
import useTexts from "../hooks/useTexts";

export default function Bio() {

	const { DATA, setData } = useContext(DataContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/, '') - 1);
	const { points } = location.state;

	// const publicDomains = [
	// 	"gmail",
	// 	"yahoo",
	// 	"outlook",
	// 	"inbox",
	// 	"icloud",
	// 	"mail",
	// 	"aol",
	// 	"protonmail",
	// ];
	const { user, setUser } = useContext(UserContext);
	const id = Cookies.get().id;
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm();
	const [email, setEmail] = useState("");
	const [input, setInput] = useState("");
	const [popUp, setPopUp] = useState(false);
	const [popup, setPopup] = useState("");
	const [updateScore1] = useScore(points, id);
	const [updateScore2] = useScore(points, id);
	const navigate = useNavigate();
	const [addAnalytics] = useAnalytics("corporate email", id, {}, user.project_id);
	const [fire, setFire] = useState(false);
	const [package_points, setPackage_points] = useState(0);

	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (!isMounted.current || !user.progress || !user.project_id) return;

		if (user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
		}
	}, [user.progress, user.project_id]);

	//=======================================
	var texts = {
		email: {
			subject: 'Thank you for completing your profile, #username#!',
			html: '\
		    <p>Hi, #username#!</p><br>\
		    <p>Thank you for completing your profile. <br>To receive the points, input this code when prompted <h3>#activation_code#</h3>.<br/><br/>Feel free to reach out to me by responding to this email or to Misha (misha@fictiontribe.com) with any questions.</p>',
		},
		sms: {
			text: 'Hi, #username#! Thank you for completing your profile. Unlock more activities here: https://app.lagoon.live/fortinet-microsoft-ignite-24/?unlock=packet_1',
		}
	};
	var templates = [
		{
			code: '#username#',
			value: user.firstName,
		},
		{
			code: '#activation_code#',
			value: user.activationCode,
		}
	];
	texts = useTexts('completing_profile', templates, texts);
	//console.log(texts);
	//=======================================

	const sendEmail = async (cEmail) => {

		await addDoc(collection(db, "mail"), {
			to: cEmail,
			message: {
				subject: texts['email']['subject'],
				html: texts['email']['html'],
			},
		});
	};

	const sendText = async (phoneNumber) => {
		const newText = addDoc(collection(db, "sms"), {
			to: phoneNumber,
			body: texts['sms']['text'],
		}).then((docRef) => {
			console.log(docRef);
		});
	};

	function onModalChange(newState) {
		setPopup(newState);
	}

	const validEmailSubmitted = async (cEmail, phoneNumber) => {
		try {
			await Promise.all([
				sendEmail(cEmail),
				sendText(phoneNumber)
			]);

			if (isMounted.current) {
				setPopUp("Thank you for completing your profile!")
				setPopUp(true);
				setFire(true);
			}
			await addAnalytics();
		} catch (error) {
			console.error('Error in validEmailSubmitted:', error);
		}
	};

	const onSubmit = async (data) => {
		try {
			const user_ref = doc(db, "users", Cookies.get().id);
			const updatedUser = { ...user };

			updatedUser.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
			updatedUser.progress[user.project_id]['packets'][packet_index].package_points = 
				parseInt(package_points) + parseInt(points);

			if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']) {
				let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];
				if (reward.name !== '') {
					updatedUser.rewards.push({
						name: reward.name,
						price: reward.price,
						image: reward.image,
						redeemed: false,
						instruction: reward?.instruction,
					});
				}
			}

			if (isMounted.current) {
				updatedUser.setprogress = false;
				setUser(updatedUser);
			}

			await updateDoc(user_ref, {
				progress: updatedUser.progress,
				companyName: data.company,
				corporateEmail: data.email,
				firstName: data.firstName ? data.firstName : user.firstName,
				lastName: data.lastName,
				phone: data.phone,
				profileComplete: true,
			});

			Cookies.set('profileComplete', true);
			
			await validEmailSubmitted(data.email, data.phone);

		} catch (error) {
			console.error('Error in onSubmit:', error);
		}
	};

	const closeModal = async () => {
		try {
			if (!isMounted.current) return;
			
			setFire(true);
			await updateScore1();
			navigate("/");
		} catch (error) {
			console.error('Error in closeModal:', error);
		}
	};
	// useEffect(() => {
	// 	if (fire) {
	// 		setTimeout(() => {
	// 			navigate("/");
	// 		}, 2000);
	// 	}
	// }, [fire]);

	return (
		<div className="bio">
			<header>
				<div className="top">
					<ArrowLeft />
					Profile
				</div>
			</header>
			<ConfettiComp fire={fire} />
			<TaskCard
				taskDescription={"Enter additional information to get more points"}
			// buttonText={"Get my points"}
			// handleClick={handleClick}
			/>
			<form className="email-form">
				<input {...register("firstName")} placeholder={user.firstName} />
				<p>{errors.firstName?.message}</p>
				<input
					{...register("lastName", { required: "Your last name is required" })}
					placeholder="Last Name"
				/>
				<p>{errors.lastName?.message}</p>
				<input
					{...register("email", {
						required: "Enter your corporate email, please",
						pattern: {
							value:
								/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: "Invalid email address",
						},
					})}
					placeholder="Corporate email"
				/>
				<p>{errors.email?.message}</p>
				<input
					{...register("company", {
						required: "Please enter your company name",
					})}
					placeholder="Company name"
				/>
				<input
					{...register("phone", {
						required: "Please enter your phone",
					})}
					placeholder="Your phone number"
				/>
				<p>{errors.phone?.message}</p>
				<button onClick={handleSubmit((data) => onSubmit(data))}>
					Complete Profile
				</button>
			</form>
			<PopUpBio trigger={popUp} closeModal={closeModal}>
				{
					<div>
						<h2>Thank you for your response!</h2>
						<p>{popup}</p>
						{/* <input
							id="activation-input"
							name="validation"
							placeholder="Input your code here"
							onChange={(e) => setInput(e.target.value)}
						/> */}
						<p id="error"></p>
					</div>
				}
			</PopUpBio>
			<Footer />
		</div>
	);
}
