import React, { useEffect, useState, useContext, useRef } from "react";
import { ReactComponent as LagoonLogo } from "../assets/logo.svg";
import { UserContext } from "./User";
import { ProgressCircleComponent, LevelProfileComponent } from '@insertcoinab/gwen-react-components';
import { DataContext } from "./DataContext";

export default function HeaderGreetings({ name, avatar, score }) {
	const [scroll, setScroll] = useState(false);
	const [time_of_day, setTimeOfDay] = useState("to see you");
	const [newAvatar, setNewAvatar] = useState(avatar || "#");
	const { user } = useContext(UserContext);
	const [newScore, setNewScore] = useState(score || 0);
	const { DATA } = useContext(DataContext);
	const [logo, setLogo] = useState("");
	
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (!isMounted.current) return;
		
		if (avatar) setNewAvatar(avatar);
		if (score) setNewScore(score);
	}, [avatar, score]);

	useEffect(() => {
		if (!isMounted.current) return;
		
		const newLogo = DATA?.logo || '';
		if (newLogo !== logo) {
			setLogo(newLogo);
		}
	}, [DATA?.logo]);

	useEffect(() => {
		const handleScroll = () => {
			if (isMounted.current) {
				setScroll(window.scrollY > 50);
			}
		};

		const setCurrentTimeOfDay = () => {
			if (!isMounted.current) return;

			const curHr = new Date().getHours();
			const timeOfDay = curHr < 12 ? "morning" : curHr < 18 ? "afternoon" : "evening";
			setTimeOfDay(timeOfDay);
		};

		window.addEventListener("scroll", handleScroll);
		setCurrentTimeOfDay();

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<header className={`header-greetings ${scroll ? "bg-solid" : ""}`}>
			<div className="logo-wrap">
				{logo ? <img src={logo} alt="Logo" /> : <LagoonLogo />}
			</div>
			<div className="greetings">
				<div id="hi">
					<div id="greet">
						<div style={{ width: '80px', margin: '24px 14px 24px 24px' }}>
							<ProgressCircleComponent
								currentStep={newScore}
								step={newScore}
								shape="shield"
								progress={newScore / 60}
								avatar={newAvatar}
								stroke={{ primary: '#6946aa', background: '#a57af7' }}
								style={{ margin: '0 20px' }}
							/>
						</div>
						Good {time_of_day}
						<p className="name"> {name}</p>
					</div>
				</div>
			</div>
		</header>
	);
}
