import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "./User";
import { DataContext } from "./DataContext";
import Cookies from "js-cookie";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import db from "../firebase";
import safeCookieOperations from '../utils/cookieUtils';

export default function ProtectedRoute({ children }) {
	const { DATA, setDATA } = useContext(DataContext);
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const { code } = useParams();
	const [searchParams] = useSearchParams();
	const isMounted = useRef(true);
	const [isInitializing, setIsInitializing] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	// Initial auth check
	useEffect(() => {
		const currentId = safeCookieOperations.get('id');
		if (currentId) {
			setIsAuthenticated(true);
		}
		setIsInitializing(false);
	}, []);

	// Cleanup on unmount
	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	// Combine user validation effects into one
	useEffect(() => {
        const validateUserAccess = async () => {
            const currentId = safeCookieOperations.get('id');
            if (!currentId || !isMounted.current) return;

            try {
                // 1. First check if user exists
                const userDoc = doc(db, "users", currentId);
                const userSnap = await getDoc(userDoc);
                
                // If user doesn't exist, clear everything and redirect
                if (!userSnap.exists() && isMounted.current) {
                    clearCookiesAndRedirect();
                    return;
                }

                const userData = userSnap.data();
                // 2. Then check project access only if we have both code and user
                if (code && userData?.project_id && userData.project_id !== code && isMounted.current) {
                    clearCookiesAndRedirect();
                    return;
                }

                // 3. Update last login if needed
                const lastLogin = safeCookieOperations.get('lastLogin');
                const now = Date.now();
                const shouldUpdateLogin = !lastLogin || 
                    (Math.floor((now - lastLogin) / 1000) / 3600) > 1;

                if (shouldUpdateLogin && isMounted.current) {
                    safeCookieOperations.set("lastLogin", now);
                    await updateDoc(userDoc, {
                        lastLogin: now,
                    });
                }
            } catch (error) {
                console.error('Error in validateUserAccess:', error);
            }
        };

		// Helper function to clear cookies and redirect
		const clearCookiesAndRedirect = () => {
			const cookiesToRemove = [
				"lastLogin",
				"didShowProgressNotificationModuleOne",
				"showProgressNotificationModuleOne",
				"profileComplete",
				"giftCardEarnedShow",
				"phoneNumber",
				"id"
			];

			// Clear notification cookies
			const allCookies = safeCookieOperations.getAll();
			Object.keys(allCookies).forEach(cookieName => {
				if (cookieName.startsWith('moduleNotificationShown_') || 
					cookieName.startsWith('moduleCompletionShown_')) {
					safeCookieOperations.remove(cookieName);
				}
			});

			// Clear specific cookies
			cookiesToRemove.forEach(cookie => {
				safeCookieOperations.remove(cookie);
			});

			navigate('/signup', { replace: true });
		};

		validateUserAccess();
	}, [user, code, navigate, isMounted]);

	// Keep the cookie/DATA update effect separate
	useEffect(() => {
        if (!isMounted.current) return;

        try {
            if (code) {
                setDATA(prevData => ({
                    ...prevData,
                    projectId: code
                }));
                safeCookieOperations.set('projectId', code, { path: '/' });
            }

            const unlock = searchParams.get("unlock");
            if (unlock) {
                safeCookieOperations.set('unlock', unlock, { path: '/' });
            }
        } catch (error) {
            console.error('Error updating cookies and data:', error);
        }
    }, [code, searchParams, setDATA, isMounted]);

	// Don't render anything while checking initial auth
	if (isInitializing) {
		return null; // or a loading spinner
	}

	// Only redirect if we're sure we're not authenticated
	if (!isAuthenticated) {
		return <Navigate to="/signup" />;
	}

	return children;
}
