import Cookies from "js-cookie";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import useAnalytics from "../hooks/useAnalytics";
import useScore from "../hooks/useScore";
import useProgress from "../hooks/useProgress";
import Footer from "./Footer";
import ConfettiComp from "./ConfettiComp";
import TaskCard from "./TaskCard";
import { UserContext } from "./User";
import ArrowLeft from "./ArrowLeft";
import {
	deleteField,
	doc,
	getDoc,
	addDoc,
	collection,
	query,
	updateDoc,
	where,
} from "firebase/firestore";
import db from "../firebase";
import { DataContext } from "./DataContext";

const Downloader = () => {

	const { DATA, setData } = useContext(DataContext);

	const { user, setUser } = useContext(UserContext);

	const location = useLocation();
	const { packet_id } = location.state;
	const { activity_i } = useParams();
	const activity_index = activity_i - 1;
	const packet_index = parseInt(packet_id.replace(/packet_/, '') - 1);
	const { points } = location.state;

	const { data } = location.state;
	const navigate = useNavigate();
	const id = Cookies.get().id;
	const [updateScore] = useScore(points, id);
	const [addAnalytics] = useAnalytics("download pdf", id, data, user.project_id);
	const [fire, setFire] = useState(false);
	const [package_points, setPackage_points] = useState(0);
	const [updateProgress] = useProgress(user.progress, id);
	const [isLoading, setIsLoading] = useState(false);

	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!isMounted.current || !user.progress || !user.project_id) return;

		if (user.progress[user.project_id]) {
			setPackage_points(user.progress[user.project_id]['packets'][packet_index].package_points);
		}
	}, [user.progress, user.project_id]);

	const update = async () => {
		try {
			const user_ref = doc(db, "users", Cookies.get().id);
			const updatedUser = { ...user };

			if (DATA['PACKETS'][packet_index].activities[activity_index]['reward']) {

				let reward = DATA['PACKETS'][packet_index].activities[activity_index]['reward'];

				if (reward.name != '') {
					updatedUser.rewards.push({
						name: reward.name,
						price: reward.price,
						image: reward.image || '',
						redeemed: false,
						instruction: reward?.instruction,
					});
				}
			}

			updatedUser.setprogress = false;
			updatedUser.progress[user.project_id]['packets'][packet_index].activities[activity_index].completed = true;
			
			updatedUser.progress[user.project_id]['packets'][packet_index].package_points = parseInt(package_points) + parseInt(points);

			if (isMounted.current) {
				setUser(updatedUser);
			}

			await updateDoc(user_ref, {
				rewards: updatedUser.rewards,
				progress: updatedUser.progress
			});
		} catch (error) {
			console.error("Error updating download progress:", error);
			throw error;
		}
	};

	const handleClick = async () => {
		try {
			setIsLoading(true);
			await Promise.all([
				updateScore(),
				addAnalytics(),
				update(),
				updateProgress()
			]);

			if (isMounted.current) {
				setFire(true);
			}
		} catch (error) {
			console.error('Error in handleClick:', error);
			if (isMounted.current) {
				navigate("/", { replace: true });
			}
		} finally {
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	};

	useEffect(() => {
		if (!fire) return;

		const timer = setTimeout(() => {
			if (isMounted.current) {
				navigate("/", { replace: true });
			}
		}, 1000);
		
		return () => clearTimeout(timer);
	}, [fire]);



	return (
		<div className="downloader">
			<header>
				<div className="top">
					<ArrowLeft />
					Download PDF
				</div>
			</header>
			<div id="top-downloader">
				<div className="flex">
					<ConfettiComp fire={fire} />
					<TaskCard
						taskDescription={data.taskDescription}
						buttonText={data.buttonText}
						handleClick={handleClick}
						imagePreview={data?.imgPreview}
						isLoading={isLoading}
					/>
					<div className="flex flex-col"></div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Downloader;
