import {
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	updateDoc,
} from "@firebase/firestore";
import { async } from "@firebase/util";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import db from "../firebase";
import safeCookieOperations from '../utils/cookieUtils';

export default function useCookie(name, defaultValue) {
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		const initializeCookie = () => {
			const cookie = Cookies.get(name);
			if (cookie) {
				setValue(cookie);
			} else {
				Cookies.set(name, defaultValue);
			}
		};

		initializeCookie();
	}, [name, defaultValue]);

	useEffect(() => {
		const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
			if (snapshot) {
				setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
			}
		});
		
		return () => {
			if (unsubscribe) {
				unsubscribe();
			}
		};
	}, []);

	const updateCookie = useCallback(
		(newValue, options) => {
			Cookies.set(name, newValue, options);
			setValue(newValue);
		},
		[name]
	);

	const deleteCookie = useCallback(() => {
		try {
			// Remove the specific cookie
			safeCookieOperations.remove(name);
	
			// Clear notification cookies
			const allCookies = safeCookieOperations.getAll();
			Object.keys(allCookies).forEach(cookieName => {
				if (cookieName.startsWith('moduleNotificationShown_') || 
					cookieName.startsWith('moduleCompletionShown_')) {
					safeCookieOperations.remove(cookieName);
				}
			});
			
			// Clear specific cookies
			const specificCookies = [
				"lastLogin",
				"didShowProgressNotificationModuleOne",
				"showProgressNotificationModuleOne",
				"profileComplete",
				"giftCardEarnedShow",
				"phoneNumber",
				"id"
			];
			
			specificCookies.forEach(cookie => safeCookieOperations.remove(cookie));
			
			setValue(null);
			
			// Only navigate if we're actually removing an auth-related cookie
			if (name === 'id' || name === 'phoneNumber') {
				navigate("/signup", { replace: true });
			}
			
			return true;
		} catch (error) {
			console.error('Error in deleteCookie:', error);
			return false;
		}
	}, [name, navigate]);

	return [value, updateCookie, deleteCookie];
}
