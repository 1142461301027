import React, { useContext, useEffect, useState, useRef } from "react";
import { DataContext } from "./DataContext";
import { UserContext } from "./User";

const ProgressBar2 = ({ background = "#e5e5e5" }) => {
    const { DATA } = useContext(DataContext);
    const { user } = useContext(UserContext);

    const [totalPoints, setTotalPoints] = useState(0);
    const [progressWidth, setProgressWidth] = useState(0);
    const lastValidScoreRef = useRef(user.score || 0);

    useEffect(() => {
        let maxPoints = 0;
        if (DATA.PACKETS) {
            DATA.PACKETS.forEach(packet => {
                packet.activities.forEach(activity => {
                    maxPoints += parseInt(activity.points || 0, 10);
                });
            });
            setTotalPoints(maxPoints+7); // temp: to account for the 5 bonus points from unlocking first packet
        }
    }, [DATA.PACKETS]);

    useEffect(() => {
        if (totalPoints > 0) {
            const userScore = user.score !== undefined ? user.score : lastValidScoreRef.current;
            if (userScore !== undefined && userScore !== null) {
                lastValidScoreRef.current = userScore;
                const percentage = (userScore / totalPoints) * 100;
                setProgressWidth(percentage);
            }
        }
    }, [user.score, totalPoints]);

    return (
        <div className="progress-bar-2">
            <div className="progressVisualRewards">
                {user.rewards &&
                    user.rewards.map((reward, i) => {
                        let percentage = (reward.price / totalPoints) * 100;
                        return (
                            <div key={i}
                                style={{ left: `${percentage}%` }}
                                data-percentage={percentage}
                                className={`progress-reward ${lastValidScoreRef.current >= reward.price ? "active" : ""}`}
                            >
                                <span>{reward.name}</span>
                            </div>
                        );
                    })
                }
            </div>
            <div className="progressVisualFull" style={{ background }}>
                <div
                    className="progressVisualPart"
                    style={{ width: `${progressWidth}%`, background: "white" }}
                ></div>
            </div>
        </div>
    );
};

export default ProgressBar2;


// import React, { useContext, useEffect, useState } from "react";
// import { DataContext } from "./DataContext";
// import { UserContext } from "./User";

// const ProgressBar2 = ({
// 	label,
// 	background = "#e5e5e5",
// 	totalPoints = 0,
// 	// expected format for visual parts
// 	visualParts = [
// 		{
// 			percentage: 0,
// 			background: "white",
// 			text: "progress",
// 			points: 0,
// 		},
// 	],
// }) => {
//     const {DATA, setData} = useContext(DataContext);
// 	// Starting values needed for the animation
// 	// Mapped by "visualParts" so it can work with multiple values dynamically
// 	// It's an array of percentage widths
    

// 	const [widths, setWidths] = useState(
// 		visualParts.map(() => {
// 			return 0;
// 		})
// 	);
// 	const { user, setUser } = useContext(UserContext);

// 	const [rewards, setRewards] = useState();
    
// 	const [newTotalPoints, setNewTotalPoints] = useState(totalPoints);
// 	const [userPoints, setUserPoints] = useState(0);

// 	useEffect(() => {
// 		// https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
// 		// You need to wrap it to trigger the animation
// 		requestAnimationFrame(() => {
// 			// Set a new array of percentage widths based on the props
// 			setWidths(
// 				visualParts.map((item) => {
// 					return item.percentage;
// 				})
// 			);
// 		});
// 	}, [visualParts]);

// 	useEffect(() => {
// 		if(user.rewards)
// 			setRewards(user.rewards);
// 	}, [user]);

// 	useEffect(() => {
//         if (!!DATA.PACKETS){
//             setUserPoints(user.score);
//             let price = 0;       
//             if (!!DATA.PACKETS){
//                 Object.values(DATA.PACKETS).map((packet) => {
//                     if (packet.activities){
//                         packet.activities.forEach((activity) => {
// 							if (activity.points) {
// 								price += parseInt(activity.points);
// 							}
//                         });
//                     }
//                 });
//             }
//             setNewTotalPoints(parseInt(price));
//         }
// 	}, [user, userPoints, DATA]);

// 	return (
// 		<div className="progress-bar-2">
// 			<div className="progressVisualRewards">
//                 {!!user.rewards ?
//                     <>
//                         {Object.values(user.rewards).map((reward, i) => {
//                             let percentage = (reward.price / newTotalPoints) * 100;
//                             return (
//                                 <div key={i}
//                                     style={{ left: percentage + "%" }}
//                                     data-percentage={percentage}
//                                     className={`progress-reward ${
//                                         userPoints >= reward.price ? "active " + i : i
//                                     }`}
//                                 >
//                                     <span>{reward.name}</span>
//                                 </div>
//                             );
//                         })}
//                     </>
//                     :
//                     null
//                 }
// 			</div>
// 			<div
// 				className="progressVisualFull"
// 				// to change the background color dynamically
// 				style={{
// 					background,
// 				}}
// 			>
// 				{visualParts.map((item, index) => {
// 					// map each part into separate div and each will be animated
// 					// because of the "transition: width 2s;" css in class "progressVisualPart"
// 					// and because of the new width ("widths[index]", previous one was 0)
// 					return (
// 						<div
// 							// There won't be additional changes in the array so the index can be used
// 							/* eslint-disable-next-line react/no-array-index-key */
// 							key={index}
// 							className={`progressVisualPart ${
// 								item.percentage >= 10 ? "show-text " : ""
// 							}`}
// 							data-modulestext={item.text}
// 							data-modulespoints={item.points}
// 							style={{
// 								width: widths[index] + "%",
// 								// setting the actual color of bar part
// 								background: item.background,
// 							}}
// 						></div>
// 					);
// 				})}
// 				{/*
// 				<div className={`points-total ${userPoints >= 5 ? "show " : ""}`}>
// 					{userPoints}
// 				</div>
// 				*/}
// 			</div>
// 		</div>
// 	);
// };

// export default ProgressBar2;
