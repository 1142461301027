import { react, useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "./User";
import {
	doc,
	collection,
	updateDoc,
	query,
	deleteDoc,
	deleteField,
	onSnapshot,
} from "firebase/firestore";
import db from "../firebase";
import Activities from "./Activities";
import { DataContext } from "./DataContext";
import ArrowLeft from "./ArrowLeft";
import Cookies from "js-cookie";
import Badges from "./Badges";
import Badge from "./Badges2";

export default function Packets() {

    const {DATA, setData} = useContext(DataContext);

	const [users, setUsers] = useState([]);
	const [packets, setPackets] = useState(false);
	const [badges2, setBadges] = useState([]);

	const [status, setStatus] = useState(0);

	const {user, setUser} = useContext(UserContext);

	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const removeFour = async () => {
		try {
			await deleteDoc(doc(db, "memory", "gameStart"));
			
			const userArray = users.map((user) => user.id);
			const updates = userArray.map(id => {
				const ref = doc(db, "users", id);
				return updateDoc(ref, {
					fourNums: deleteField(),
					matchNumber: deleteField(),
				});
			});
			
			await Promise.all(updates);
		} catch (error) {
			console.error("Error resetting timer:", error);
		}
	};

	useEffect(() => {
		let unsubscribe = () => {};

		if (users.length === 0) {
			unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
				if (!isMounted.current) return;

				const updatedUsers = snapshot.docs.map((doc) => ({ 
					...doc.data(), 
					id: doc.id 
				}));
				setUsers(updatedUsers);

				const currentUser = updatedUsers.find(
					user => user.id === Cookies.get().id
				);
				if (currentUser && isMounted.current) {
					setUser({ ...currentUser });
				}
			});
		}

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (!isMounted.current || !user.setprogress) return;

		try {
			setStatus(prev => !prev);

			const tempPackets = [];

			if (DATA['PACKETS'] && 
				user.progress && 
				DATA.ID && 
				user.progress[DATA.ID]) {

				DATA['PACKETS'].forEach((packet, i) => {
					let propsObj = {
						packet: packet,
						progress: user.progress[DATA.ID]['packets'][i],
					};

					console.log(propsObj);

					let unlock = DATA['unlock'] || false;

					if (propsObj['packet'] && propsObj['progress']) {
						tempPackets.push(
							<Activities 
								key={`activity-${i}`}
								props={propsObj} 
								i={i} 
								unlock={unlock}
							/>
						);
					}
				});
			}

			if (isMounted.current) {
				setPackets(tempPackets);
			}
		} catch (error) {
			console.error("Error updating packets:", error);
		}
	}, [user, user.setprogress, DATA, DATA['existingProject'], DATA['unlock']]);

	return (
		<div className="packets">
			<header>
				<div className="top">
					<ArrowLeft />
					Activities
				</div>
			</header>
			{packets}
			<button onClick={removeFour}>Reset Timer</button>
		</div>
	);
}
