import db from "../firebase";
import { useContext } from "react";
import { UserContext } from "../components/User";
import { doc, updateDoc, onSnapshot } from "@firebase/firestore";

export default function useScore(scoreIncrement, id) {
	const { user, setUser } = useContext(UserContext);
	let score = 0;
	if (user) {
		score = parseInt(user.score);
	}

	const updateScore = async () => {
		const _ref_users = doc(db, "users", id);
		const newScore = parseInt(score) + parseInt(scoreIncrement);
		await updateDoc(_ref_users, {
			score: newScore
		});
		setUser(prev => ({
			...prev,
			score: newScore
		}));
	};

	return [updateScore];
}
